@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  background-color: #fff;
  color: #000;
}

.text-primary {
  color: #0356fc !important;
}

.text-secondary {
  color: #43b77a !important;
}

.text-light {
  color: rgba(247, 247, 247, 0.925) !important;
}

.btn-primary {
  background: #0356fc !important;
  color: #fff !important;
  border: none;
}

.mmt {
  margin-top: 3rem;
}
.mmt-5 {
  margin-top: 5rem;
}
.mmt-10 {
  margin-top: 10rem;
}
.subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  word-spacing: 7px;
}

.bs {
  box-shadow: 0px 0px 5px 1px #f2f2f26e;
}

.nav-tabs {
  border-bottom: none !important;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: #000 !important;
  font-weight: bold;
  color: #0356fc;
  font-size: 1.1rem;
} */
/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff !important;
  font-weight: bold;
} */

.form-control {
  background-color: transparent !important;
  color: #fff !important;
}
::-webkit-input-placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}
::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

.con1 {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.ovrlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #0356fc;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.con1:hover .ovrlay {
  bottom: 0;
  height: 100%;
}

.text {
  color: #000;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.iimg {
  height: 200px;
  width: 300px;
  border: 1px solid #000;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rh4 {
    font-size: 0.8rem !important;
  }
  .rp {
    font-size: 0.5rem;
  }
}

.showcase{height:60vh;width:100vw;display:block;background-size:cover;background-image:linear-gradient(rgba(0, 0, 0, 0.411), rgba(0, 0, 0, 0.466)),url(/static/media/bg1.89dbd195.jpeg)}.contact-showcase{height:50vh;width:100vw;display:block;background-size:cover;background-image:linear-gradient(rgba(0, 0, 0, 0.521), rgba(0, 0, 0, 0.459)),url(/static/media/service.82f01e98.jpeg)}.partner-showcase{height:50vh;width:100vw;display:block;background-size:cover;background-image:linear-gradient(rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.247)),url(/static/media/partner-bg.1fe65eaa.jpeg)}.showcasecard{background-color:#d3d3d3;padding:2rem;width:400px;border-radius:2rem}.info2{background-color:#faebd7;padding:3rem 0}.industry-image{border-radius:100%;height:300px;width:300px;border:3px solid #000}
.cus-nav{background-color:#fff;padding:.5rem 0;box-shadow:0 .125rem .25rem rgba(0,0,0,.075) !important}.sticky{position:fixed;top:0;width:100%;z-index:1111}.link-style{color:#000 !important;margin-left:1rem;font-weight:bolder;text-decoration:none !important;text-align:center;font-size:1.3rem}.nav-logo{height:60px}.linkh{color:#666;display:inline-block;margin:0;text-transform:capitalize}.linkh:hover:after{-webkit-transform:scaleX(1);transform:scaleX(1)}.linkh.fromRight:after{-webkit-transform-origin:100% 50%;transform-origin:100% 50%}.linkh.fromLeft:after{-webkit-transform-origin:0% 50%;transform-origin:0% 50%}
