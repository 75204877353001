@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
  background-color: #fff;
  color: #000;
}

.text-primary {
  color: #0356fc !important;
}

.text-secondary {
  color: #43b77a !important;
}

.text-light {
  color: rgba(247, 247, 247, 0.925) !important;
}

.btn-primary {
  background: #0356fc !important;
  color: #fff !important;
  border: none;
}

.mmt {
  margin-top: 3rem;
}
.mmt-5 {
  margin-top: 5rem;
}
.mmt-10 {
  margin-top: 10rem;
}
.subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  word-spacing: 7px;
}

.bs {
  box-shadow: 0px 0px 5px 1px #f2f2f26e;
}

.nav-tabs {
  border-bottom: none !important;
}

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  background-color: #000 !important;
  font-weight: bold;
  color: #0356fc;
  font-size: 1.1rem;
} */
/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #fff !important;
  font-weight: bold;
} */

.form-control {
  background-color: transparent !important;
  color: #fff !important;
}
::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
}

.con1 {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.ovrlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #0356fc;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.con1:hover .ovrlay {
  bottom: 0;
  height: 100%;
}

.text {
  color: #000;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.iimg {
  height: 200px;
  width: 300px;
  border: 1px solid #000;
}

@media only screen and (max-width: 600px) {
  .rmt {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rh4 {
    font-size: 0.8rem !important;
  }
  .rp {
    font-size: 0.5rem;
  }
}
