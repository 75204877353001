.showcase {
  height: 60vh;
  width: 100vw;
  display: block;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.411), rgba(0, 0, 0, 0.466)),
    url("../../assets/img/bg1.jpeg");
}

.contact-showcase {
  height: 50vh;
  width: 100vw;
  display: block;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.521), rgba(0, 0, 0, 0.459)),
    url("../../assets/img/service.jpeg");
}

.partner-showcase {
  height: 50vh;
  width: 100vw;
  display: block;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.26), rgba(0, 0, 0, 0.247)),
    url("../../assets/img/partner-bg.jpeg");
}

.showcasecard {
  background-color: lightgray;
  padding: 2rem;
  width: 400px;
  border-radius: 2rem;
}

.info2 {
  background-color: antiquewhite;
  padding: 3rem 0;
}

.industry-image {
  border-radius: 100%;
  height: 300px;
  width: 300px;
  border: 3px solid #000;
}
