.cus-nav {
  background-color: #fff;
  padding: 0.5rem 0;
  // border-bottom: 1px solid rgba(255, 255, 255, 0.336);
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1111;
}

.link-style {
  color: #000 !important;
  margin-left: 1rem;
  font-weight: bolder;
  text-decoration: none !important;
  text-align: center;
  font-size: 1.3rem;
}

.nav-logo {
  height: 60px;
}

.linkh {
  color: #666;
  display: inline-block;
  margin: 0;
  text-transform: capitalize;
}

.linkh:hover:after {
  transform: scaleX(1);
}
.linkh.fromRight:after {
  transform-origin: 100% 50%;
}
.linkh.fromLeft:after {
  transform-origin: 0% 50%;
}
